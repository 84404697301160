.newHeader {
    .navbar {
        padding: 0;
        width: 100%;
        margin-top: 1.875rem;
        position: unset !important;
    
    
        a {
            text-decoration: none;
        }
    @include respond(tablet) {
        padding-bottom: convertToRem(25)
    }


    &__container {
       display: flex;
       justify-content: space-between;
    }

    &__logo {
        .logoBox  {
            width: 14.5625rem;
            height: convertToRem(32);

            @include respond(phone) {
                width: 11rem;
            }
        }       
    }

    &__actions {
        display: flex;
        align-items: center;

        a {
            line-height: 24px;
        }

        &--sm {
            display: none;
            @include respond(tablet) {
                display: flex;
                z-index: 1100;
            }

            a,  button#signin {
                text-decoration: none;
                width: 100%;
                // margin-left: 20%;
                width: 80%;
                margin-top: 25px;
                padding-bottom: 16px;
                border-bottom: 1px solid $lightGrey;
            }

            button#signin {
                margin-top: 15px;
                padding-left: 0;
            }

            #signup {
                margin-top: 18px;
                width: 80%;
            }

            .mbNavLink {
                text-align: left;
            }
        }

        .navLink {
            font-size: 1rem;
            color: rgb(19, 19, 19);
            font-family: Inter;
            letter-spacing: 0.5px;
            font-weight: medium;
        
            &:hover {
                color: #000;
            }
        }

        &--menuIcon {
            display: none;

            img {
                width: 2rem;
                cursor: pointer;

                @include respond(phone) {
                    width: 1.4rem;
                }
            }

            .cross {
                width: convertToRem(26);

                @include respond(phone) {
                    width: 1.1rem;
                }
            }
        }

        @include respond(tablet) {
            flex-direction: column;
            border-top: 1px solid $lightGrey;
            &--bigScreen {
                display: none;
            }

            &--menuIcon {
                display: block;
            }
        }
        #signin {
            background-color: #fff;
            border: 1px solid #fff;
            margin: 0 1.625rem;

            @include respond(tablet) {
                margin: 0;
            }
        }

        #signup {
            background: linear-gradient(319.51deg, #BC40FD 0%, #7100FF 93.26%);
            box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
            padding: 0.63rem 1.25rem;
            border-radius: 1.25rem;
            @include gradientbtn;
            span {
                color: #fff;
            }
        }
    }
}


.navLink {
    font-size: 1rem;
    color: rgb(19, 19, 19);
    font-family: Inter;
    letter-spacing: 0.5px;
    font-weight: medium;

    &:hover {
        color: #000;
    }
}
}