.access {
    .imgBg {
        width: 100%;
        height: 53.54vw;
        background-image: url(../../../imgs/Access.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin-top: 70px;

        display: flex;
        align-items: center;
        justify-content: center;

        @include respond(phone) {
            background-image: url(../../../imgs/AccessSmall.png);
            width: 98vw;
            height: 80vw;
            margin-top: 180px;
            padding-bottom: 100px;
            margin-bottom: -30px;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 510px;
            text-align: center;

            @include respond(phone) {
                width: 98%;
            }

            h2 {
                font-size: 36px;
                font-weight: bold;
                font-family: inter;
                margin-bottom: 8px;
                margin-top: 10px;

                @include respond(phone) {
                    font-size: 27px;
                    margin-bottom: 10px;
                    margin-top: -10px;
                    line-height: 30px;
                } 
           }
   
           span {
               font-size: 20px;
               line-height: 26px;
               color: #989caa;

               @include respond(phone) {
                font-size: 17px;
                margin-bottom: 25px;
            } 
           }

           #join {
                background: linear-gradient(320.68deg, #D656FC -13.3%, #7100FF 82.21%);
                box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
                border-radius: 25px;
                padding: 15px 30px;
                margin-top: 48px;
                @include gradientbtn;
                background-image: linear-gradient(320deg,#7100FF, #D656FC 50%, #D656FC 55%, #7300ffe5);  


                @include respond(phone) {
                    margin-top: 0px;
                    padding: 13px 30px;
                }

                .bp3-button-text {
                    margin-bottom: 0;
                }
                span {
                    color: #fff;
                    font-size: 17px;
                    letter-spacing: 0.53px;
                }
           }
        }
    }
}


