.discover {
    margin-top: 160px;
    text-align: center;
    background: linear-gradient(359.75deg, #EEF1FA 0.31%, #FFFFFF 99.88%);
    width: 100%;
    height: 866px;
    padding-top: 30px;

    @include respond(phone) {
       margin-top: 80px;
       height: 430px;
    }

    @include respond(bigScreen) {
        height: 950px;
    }

    .contentBox {
        height: 100%;
        h2 {
            color: #000;
            font-size: 38px;
            font-weight: 600;
            font-family: inter;

            @include respond(phone) {
               font-size: 30px;
               line-height: 35px;
             }
        }
    }

    &__imgBox {
        background: #fff;
        width: 64%;
        margin: 0 auto;
        border: 0.72px solid #E9E9F3;
        box-shadow: 0px 2px 3px rgba(53, 30, 88, 0.03);
        border-radius: 16.2px;
        margin-top: 70px;
        padding: 20px;
        box-sizing: border-box;
        height: 600px;

        @media only screen and (max-width: 1500px) {
            height: 550px;
        }

        @media only screen and (max-width: 1300px) {
            height: 500px;
        }

        @media only screen and (max-width: 1000px) {
            height: 400px;
        }

        @include respond(phone) {
            width: 95%;
            height: 220px;
            margin-top: 40px;
            padding: 16px;
        }

        @include respond(bigScreen) {
            height: 650px;
        }
    }
}

.discover .contentBox
{
  width: 100%;
  height: 100%;
}

/* Mask Copy */

// position: absolute;
// width: 1026px;
// height: 556px;
// left: 207px;
// top: 1064px;

// background: #FFFFFF;
// border: 0.72px solid #E9E9F3;
// box-shadow: 0px 2px 3px rgba(53, 30, 88, 0.03);
// border-radius: 16.2px;
