html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 5px;
}



/* From Home.scss */
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap');

.home_page{
    --grayBg: #ffffff;
    background: white;
}
.home_page section .row {
    width: 100% !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.home_page .dna_part{
    /* background-size: contain; */
    height: 755px;
}
.home_page .space0{
    margin: 0!important;
    padding: 0!important;
}
.home_page .group6{
    position: absolute;
    pointer-events: none;
    left:0px;
    top:-85px;
}
.home_page .dna_tool{
    margin-top: 10rem  !important;
    max-width: 480px;
}
/* .home_page h2{
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 50px;
    color: #000000;
} */
.home_page .sub_title_1{
    position: relative;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;

    color: #9B9CAA;
}

.home_page .try{
    margin-top: 0rem;
    background: #7100FF;
    box-shadow: 0px 13px 15px rgba(51, 105, 254, 0.172257);
    border-radius: 8px;
    width: 100%;
    height: 54px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 19.8px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.61875px;
    color: #FFFFFF;
}
button.try:hover {
background: #5600FF;
}

.home_page .form_input{
    background: #FFFFFF;
    box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.157944);
    border-radius: 8px;
}
.home_page .dna_img_part{
    margin-top: 8rem  !important;
}
.home_page .circural{
    width: 100%;
    max-width: 538px;
    max-height: 479px;
}

.home_page .cloud {
    margin-top: 6rem;
}
.home_page .cloud_row{
    background: url("https://mendelgen.com/img/Path.jpg") no-repeat;
    background-size: cover;
    height:663px;
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.home_page .cloud .row .text-block {
    text-align: left;
    padding-top: 6rem;
    min-width:317px ;
    padding-right: 10%;
}

.home_page .cloud_sign_up {
    background: #7100FF;
    box-shadow: 0px 13px 15px rgba(51, 105, 254, 0.172257);
    border-radius: 30.8571px;
    width: 123px;
    height: 54px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 19.8px;
    line-height: 24px;
    border: none;
    /* identical to box height */

    letter-spacing: 0.61875px;

    color: #FFFFFF;
}

.home_page .gif {
    padding-top: 0rem;
}

.home_page .gif.darkmode{
    margin-top: 0rem;
    padding-top: -5rem;
}


.home_page .gif .gif_row{
    position: relative;
    background: url("https://mendelgen.com/img/Path1.png") no-repeat;
    background-size: cover;
    height:663px;
    padding-top: 5em;
}
.home_page .gif .gif_left {
    text-align: left;
}

.home_page .gif .dark_img_part{

    padding-left: 3rem;
    padding-right: 0rem;
}

.home_page .gif .left_img_part{

    padding-left: 0rem;
    padding-right: 3rem;
}

.home_page .gif .dark_img_wrap
{
    max-width: 782px;
    overflow-x:hidden;
    direction: ltr;
    filter: drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.211566));
}

.home_page .codon .left_img_wrap
{
    max-width: 782px;
    overflow-x:hidden;
    direction: rtl;
    filter: drop-shadow(10px 20px 20px rgba(0, 0, 0, 0.211566));

}

button.cloud_sign_up:hover {
background: #5600FF;
}


#form_2 {
    position: relative;
    top: 168px;
    padding-left: 30px;
}

#cloud_1 {
    position: absolute;
    left: 0%;
    right: 80.53%;
    top: 15%;
    /* top: 8.97%; */
    bottom: 38.26%;
}

.little {
    border: none;
    background: transparent;
}

.empty {
    height: 244px;
}

button:focus {
    outline: none !important;
}

@media only screen and (max-width: 988px){
    .gif_left {
        margin-top: 3rem;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 988px){
    .gif_right {
        margin-top: 3rem;
        margin-bottom: 2rem;
    }

}
@media only screen and (max-width:810px){
    .dark_img_part{
        padding-left: 0 !important;
    }
}
@media only screen and (max-width: 768px){
    .home_page .gif {
        margin-top:-10rem;
        padding-top: 0rem;
    }
}

@media only screen and (max-width: 768px){
    .home_page .msa {
        margin-top: 0rem;
        padding-top: 5rem;
    }
}
@media only screen and (max-width: 600px) {
    .home_page .dna_tool{
        margin-top: 6rem  !important;
    }
    .home_page .dna_img_part{
        margin-top: 4rem  !important;
    }
    .home_page .cloud {
        margin-top: 3rem;
    }
  }

  @media only screen and (max-width: 320px) {
    .cloud .row .text-block {
        padding-right: 0%;
    }
  }

  .left_img_part {
    padding-left: 0px;
  }

  .wide_btn {
    width: 180px !important;
  }

  a {
    color: #7100FF;
  }

  button {
      cursor: pointer;
      border-width: 0px;
  }



  /* From SignUp.css */

  
  @import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap');
  .lead {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.375px;
      color: #101010;
  }
  
  .homeTerm {
      margin-top: 15px;
  }
  
  .modal-header {
      border-bottom: none !important;
  }
  .modal-header .close {
      background: #9B9CAA;
      mix-blend-mode: normal;
      opacity: 0.11;
      border-radius: 8px;
      width: 36px;
      height: 36px;
      padding: 0 !important;
      margin-top: 2px;
  }
  .btn {
      left: 45.05%;
      right: 45.16%;
      width : 86px;
      bottom: 5.99%;
  
      background: #7100FF;
      box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
      border-radius: 20px;
  }
  label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
  
      letter-spacing: 0.375px;
      color: #101010;
  }
  .modal-title {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      /* identical to box height */
      color: #000000;
      margin-top: 35px;
  }
  #sub_title {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 23px;
      /* identical to box height */
      color: #9B9CAA;
  }
  #stone {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
  
      text-align: right;
      letter-spacing: 0.375px;
  
      color: #7100FF;
  }
  .modal-footer {
      display: flex;
      justify-content: center;
      border-top: none !important;
      height: 80px;
  }
  .modal-60w{
      min-width: 60%;
  }
  .signup_container .padding_header {
      padding-left: 6rem;
      padding-right: 6rem;
  }
  .signup_container .modal_form {
      padding-left: 6rem;
      padding-right: 6rem;
  }
  .mail {
      margin-bottom: 20px;
  }
  .name {
      margin-bottom: 20px;
  }
  .password {
      margin-bottom: 10px;
  }
  .term {
      margin-bottom: 15px;
  }
  .modal-body form {
      padding-top: 0rem !important;
  }
  .signup_container input {
      height: 40px !important;
  }
  .warn {
      display: flex !important;
      color: red;
      justify-content: flex-end;
      height: 18px;
      font-family: Lato;
      font-size: 11px;
    }
  
    .hide {
      display: flex !important;
      visibility: hidden;
      height: 20px;
    }
    @media only screen and (max-width: 800px) {
      .signup_container .padding_header {
          padding-left: 3rem;
          padding-right: 3rem;
  
      }
      .signup_container .modal_form {
          padding-left: 3rem!important;
          padding-right: 3rem!important;
      }
    }
  @media only screen and (max-width: 700px) {
      .signup_container .padding_header {
          padding-left: 0rem;
          padding-right: 0rem;
  
      }
      .signup_container .modal_form {
          padding-left: 0rem!important;
          padding-right: 0rem!important;
      }
    }
  

    .lead {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        text-align: center;
        letter-spacing: 0.375px;
        color: #101010;
    }
    
    .wizard_container .modal-header {
        border-bottom: none !important;
        padding: .5rem .5rem;
    }
    .modal-header .close {
        background: #9B9CAA;
        mix-blend-mode: normal;
        opacity: 0.11;
        border-radius: 8px;
        width: 36px;
        height: 36px;
        padding: 0 !important;
        margin-top: 2px;
    }
    .btn {
        left: 45.05%;
        right: 45.16%;
    
        bottom: 5.99%;
        width : 86px;
        background: #7100FF;
        box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
        border-radius: 20px;
    }
    label {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
    
        letter-spacing: 0.375px;
    
        color: #101010;
    }
    .modal-title {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 36px;
        /* identical to box height */
        color: #000000;
        margin-bottom: 15px;
        margin-top: 15px;
        width: 100%;
        text-align: center;
    }
    #sub_title {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        /* identical to box height */
        color: #9B9CAA;
        padding-left: 0.5rem;
        width: 100%;
        text-align: center
    }
    #stone {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-decoration: none;
        cursor: pointer;
        /* identical to box height */
    
        text-align: right;
        letter-spacing: 0.375px;
        color: #7100FF;
    }
    
    .modal-body {
        padding-top: 0;
    }
    
    .modal-body form .form-group .row {
        justify-content: space-between;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .modal-footer {
        display: flex;
        justify-content: center;
        border-top: none !important;
    }
    
    .modal-50w{
        min-width: 50%;
    }
    
    .modal-header .close-row {
        justify-content: flex-end;
    }
    
    .modal-header .close-row button {
        margin: 0;
    }
    
    .login_container .padding_header {
        padding-left: 8rem;
        padding-right: 8rem;
    
    }
    .login_container .modal_form {
        padding-left: 3rem!important;
        padding-right: 3rem!important;
    }
    .mail {
        margin-bottom: 30px;
    }
    .login_container input {
        height: 49px !important;
    }
    .password {
        margin-bottom: 40px;
    }
    @media only screen and (max-width: 800px) {
        .login_container .padding_header {
            padding-left: 3rem;
            padding-right: 3rem;
    
        }
        .login_container .modal_form {
            padding-left: 3rem!important;
            padding-right: 3rem!important;
        }
      }
    @media only screen and (max-width: 700px) {
        .login_container .padding_header {
            padding-left: 0rem;
            padding-right: 0rem;
    
        }
        .login_container .modal_form {
            padding-left: 0rem!important;
            padding-right: 0rem!important;
        }
      }
    
      @import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap');
.lead {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.375px;
    color: #101010;
}
.modal-header {
    border-bottom: none !important;
}
.modal-header .close {
    background: #9B9CAA;
    mix-blend-mode: normal;
    opacity: 0.11;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    padding: 0 !important;
    margin-top: 2px;
}
.btn-email {
    left: 45.05%;
    right: 45.16%;
    width: 141px !important;
    bottom: 5.99%;
    margin-bottom: 151px;
    background: #7100FF;
    box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
    border-radius: 20px;
}
label {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.375px;

    color: #101010;
}
.modal-title {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height */
    color: #000000;
    margin-bottom: 15px;
    margin-top: 35px;
}
#sub_title {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 23px;
    /* identical to box height */
    color: #9B9CAA;
}
#stone {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 0.375px;

    color: #7100FF;
}
.modal-body form {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.modal-footer {
    display: flex;
    justify-content: center;
    border-top: none !important;
}
.modal-60w{
    min-width: 60%;
}
.reset_container .padding_header {
    padding-left: 6rem;
    padding-right: 6rem;

}
.reset_container .modal_form {
    padding-left: 6rem;
    padding-right: 6rem;
}
.reset_container input {
    height: 49px !important;
}
.email {
    margin-bottom: 40px;
}

@media only screen and (max-width: 800px) {
    .reset_container .padding_header {
        padding-left: 3rem;
        padding-right: 3rem;

    }
    .reset_container .modal_form {
        padding-left: 3rem!important;
        padding-right: 3rem!important;
    }
  }
@media only screen and (max-width: 700px) {
    .reset_container .padding_header {
        padding-left: 0rem;
        padding-right: 0rem;

    }
    .reset_container .modal_form {
        padding-left: 0rem!important;
        padding-right: 0rem!important;
    }
  }


  @import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap');
.lead {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.375px;
    color: #101010;
}
.modal-header {
    border-bottom: none !important;
}
.modal-header .close {
    background: #9B9CAA;
    mix-blend-mode: normal;
    opacity: 0.11;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    padding: 0 !important;
    margin-top: 2px;
}
.btn {
    left: 45.05%;
    right: 45.16%;

    bottom: 5.99%;
    width : 86px;
    background: #7100FF;
    box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
    border-radius: 20px;
}
label {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.375px;

    color: #101010;
}
.modal-title {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height */
    color: #000000;
    margin-bottom: 15px;
    margin-top: 35px;
}
#sub_title {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 23px;
    /* identical to box height */
    color: #9B9CAA;
}

.modal-body form .form-group .row {
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
}
.modal-footer {
    display: flex;
    justify-content: center;
    border-top: none !important;
}

.modal-60w{
    min-width: 60%;
}
.new_pw_container .padding_header {
    padding-left: 6rem;
    padding-right: 6rem;

}
.new_pw_container .modal_form {
    padding-left: 6rem;
    padding-right: 6rem;
}
.newPassword {
    margin-bottom: 30px;
}
.new_pw_container input {
    height: 49px !important;
}
.confirmPassword {
    margin-bottom: 40px;
}
@media only screen and (max-width: 800px) {
    .new_pw_container .padding_header {
        padding-left: 3rem;
        padding-right: 3rem;

    }
    .new_pw_container .modal_form {
        padding-left: 3rem!important;
        padding-right: 3rem!important;
    }
  }
@media only screen and (max-width: 700px) {
    .new_pw_container .padding_header {
        padding-left: 0rem;
        padding-right: 0rem;

    }
    .new_pw_container .modal_form {
        padding-left: 0rem!important;
        padding-right: 0rem!important;
    }
  }

  

  
.template .headline {
    background: linear-gradient(1.79deg, rgba(127, 163, 178, 0.0766499) 97.98%, rgba(255, 255, 255, 0.0001) 118.5%);
    height: 316px;
}



.template .headline .head {
    display: flex;
    justify-content: center;
    padding-top: 160px;
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
}
.template .headline .subHead {
    display: flex;
    justify-content: center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    /* identical to box height */
    color: #9B9CAA;
}
.template .body {
    background: #ffffff;
}
.template .body .text {
    padding: 6% 10%;
}
.template .body .text div {
    text-align: justify;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;

    color: #070714;
}
@media only screen and (max-width: 988px){
    .template .headline .head {
        font-size: 36px;
    }
    .template .headline .subHead {
        font-size: 22px;
    }
    .template .body .text div {
        font-size: 22px;
    }
}
@media only screen and (max-width:810px){
    .template .headline .head {
        font-size: 32px;
    }
    .template .headline .subHead {
        font-size: 20px;
    }
    .template .body .text div {
        font-size: 20px;
    }
}
@media only screen and (max-width: 768px){
    .template .headline .head {
        font-size: 30px;
    }
    .template .headline .subHead {
        font-size: 18px;
    }
    .template .body .text div {
        font-size: 18px;
    }
}
@media only screen and (max-width: 600px) {
    .template .headline .head {
        font-size: 28px;
    }
    .template .headline .subHead {
        font-size: 16px;
    }
    .template .body .text div {
        font-size: 16px;
    }
}

  @media only screen and (max-width: 320px) {
    .template .headline .head {
        font-size: 25px;
        padding-top: 30%;
    }
    .template .headline .subHead {
        font-size: 12px;
    }
    .template .body .text div {
        font-size: 12px;
    }
  }

.table-filename {
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    display: flex;
    align-items: center;
}

.table-filename img {
    margin-right: 25px;
}

.template .bp3-dialog-body {
    height: 100%;
    margin: 0;
    padding: 20px;
}

.savedDataList .ReactTable .rt-tbody .rt-td {
    padding: 12px 8px;
}

.template .savedDataList .ReactTable .rt-tbody .rt-td:first-child {
    flex: 300 0 auto;
    min-width: 500px;
}

.savedDataList .ReactTable .rt-tbody .rt-td:last-child {
    display: block;
    text-align: right;
}

.template .ReactTable .rt-thead .rt-resizable-header:first-child {
    flex: 300 0 auto;
    min-width: 500px;
}

.template .menu-list {
    position: absolute;
    top: 30px;
    right: 30px;
}

.template .ReactTable.ReactTable .rt-tbody .rt-tr-group {
    max-height: 60px;
    position: relative;
}

.template .ReactTable .rt-thead {
    background: rgba(255, 255, 255, 0.8);
}

.template .ReactTable .rt-tbody {
    background: rgba(255, 255, 255, 0.8);
}

.template .bp3-menu {
    border: 1px solid rgba(100, 100, 100, 0.2);
}

.template .ReactTable.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: 1px solid rgba(155, 156, 170, 0.25);
}

.template .ReactTable.ReactTable .rt-tbody .rt-tr-group:last-child {
    border-bottom: none;
}

.text-docs {
  align-content: center;
  background-color: #fff;
  width: 100vw;
  margin-left: -50px;
  margin-top: -5.5rem;
}

@media only screen and (min-width: 800px) {
    .text-docs {
        width: calc(100vw - 310px);
    }
}

.navbar {
  position: fixed;
}

.selected {
  background-color: #d2dceb;
}

.new-line {
  white-space: pre-line;
}

@media only screen and (max-width: 800px) {
    .data-table-search-and-clear-filter-container {
        width: 100%;
    }
    .tg-test-redux-form-search-input {
        width: 100%;
    }

    .ReactTable .rt-th, .ReactTable .rt-td {
        min-width: 180px !important;
        white-space: unset !important;
    }

    .ReactTable .rt-th, .ReactTable .rt-td:nth-child(2) {
        min-width: 10px !important;
    }

    .data-table-footer {
        border-top: 1px solid #e3e3e3;
        padding-top: 10px;
    }
}

.tg-sort-arrow-container .bp3-icon {
    display: inline;
    color: #9B9CAA;
}

.tg-react-table-column-header > div {
    margin-left: 5px !important;
}



/* From SideNav */

.sidenav {
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 2.236em;
    background: #E4EAF3;
  
  }
  
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    color: #182026;
    display: block;
  }
  
  .sidenav a:hover {
    background: #EEF1FA;
  }
  
  .sectionTitle {
    font-weight: bold;
  }
  .screenshot {
    max-width: 60%; padding-bottom: 20px;
  }
  
  .fullScreenshot {
    max-width: 100%; padding-bottom: 20px;
  }
  
  .splitScreenshot {
    max-width: 50%;
    padding-bottom: 20px;
    padding-right: 10px;
  }
  
  p {
        overflow-wrap: anywhere;
  }
  
  
  .text-docs {
    padding: 6.236em 3.236em 3.236em 3.236em;
  }
  
  .wordmark {
    font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      width: 99px;
      height: 24px;
      margin-left: 0.5rem;
      padding-left: 0px;
  }
  
  
  .logo {
    padding-left: 16px;
  }
  
  .sidenav::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    }
  
  
  
  @media only screen and (min-width: 450px) {
  
     .footer_bar { margin-left: 260px;}
     /* .text-docs { margin-left: 260px;} */
   }
  
   @media only screen and (max-width: 800px) {
  
     .navbar-brand {display: none;}
     .beta { display: none;}
      #signup {display: none;}
      #signin {display: none;}
      .sidenav {display: none;}
      .text-docs {margin-left: 0px; width: 100%; padding: 6.236em 1.236em 1.236em 1.236em;}
      .body {width: 100%; background-color: #FFFFFF}
      .footer_bar { margin-left: 0px;}
      .splitScreenshot { max-width: 100%}
      html, body, #demo { background-color: #FFFFFF}
      .logo { padding-left: 25px; padding-top: 13px;}
      .sticky-top {z-index: 999}
  
  
    }
  
    /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 30px;
    height: 25px;
    right: 36px;
    top: 30px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #5C7080;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #7100FF;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #E4EAF3;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  
    color: #182026;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  .bm-item:hover {
    color: #000;
  }
