@function convertToRem($value) {
 @return $value/16 + rem;
}

$lightGrey: #DCDEE9;

@mixin gradientbtn {
  // background-image: linear-gradient(320deg,#7100FF 0%, #BC40FD 0%, #bb40fddf, #7300ffe5);
  background-image: linear-gradient(320deg,#7100FF, #BC40FD , #bb40fddf 45%, #7300ffe5);  
  background-size: 300% 100%;
  moz-transition: all .4s  ease-in-out;
  -o-transition: all .4s  ease-in-out;
  -webkit-transition: all .4s  ease-in-out;
  transition: all .4s  ease-in-out;

  &:hover {
    background-position: 100% 0;
    moz-transition: all .4s  ease-in-out;
    -o-transition: all .4s  ease-in-out;
    -webkit-transition: all .4s  ease-in-out;
    transition: all .4s  ease-in-out;
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }

  @if $breakpoint == bigTab {
    @media only screen and (max-width: 1100px) {
      @content;
    }
  }

  @if $breakpoint == smLaptop {
    @media only screen and (max-width: 1330px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media only screen and (min-width: 1550px) {
      @content;
    }
  }


  @if $breakpoint == bigScreen {
    @media only screen and (min-width: 1700px) {
      @content;
    }
  }
//   @if $breakpoint == tab-port {
//     @media only screen and (max-width: 56.25em) {
//       @content;
//     } //900px
//   }
//   @if $breakpoint == tab-land {
//     @media only screen and (max-width: 75em) {
//       @content;
//     } //1200px
//   }
//   @if $breakpoint == desk {
//     @media only screen and (max-width: 86em) {
//       @content;
//     } // 1380px
//   }
//   @if $breakpoint == big-desktop {
//     @media only screen and (min-width: 112.5em) {
//       @content;
//     } //1800
//   }
}