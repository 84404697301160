.slugContainer {
    display: flex;
    margin-top: 8rem;
    min-height: 80vh;
    position: relative;
    .htmlData {
        // margin-left: 300px;
        font-family: Inter;
        @media only screen and (min-width: 800px) {
            margin-left: 300px;

        }
        margin-left: 50px;
        margin-right: 50px;
        @media only screen and (max-width: 450px) {
            margin-left: 10px;
            margin-right: 10px;
        }
        // table {
        //     border: 1px solid #dee2e6;

        //     tr:nth-of-type(odd) {
        //         background-color: rgba(0,0,0,.05);
        //     }

        //     td {
        //         border: 1px solid #dee2e6;
        //         padding: .75rem;
        //         vertical-align: top;
        //     }
        // }

        p {
            .cmsImage {
                width: 60%;
            }
        }
     
    }
}