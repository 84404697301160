.feature {
    margin-top: 180px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 155px;

    @include respond(bigTab) {
        margin-top: 72px;
        margin-bottom: 90px;
    }

    &__top {
        width: 700px;

        @include respond(phone) {
            width: 90%;
        }

        h2 {
            font-size: 36px;
            color: #000;
            font-family: Inter;
            font-weight: 600;
            margin-bottom: 14px;

            @include respond(phone) {
                font-size: 27px;
            }
        }

        span {
            font-size: 20px;
            color: #989caa;
            line-height: 26px;

            @include respond(phone) {
                font-size: 17px;
            }
        }
    }

    &__lists {
        margin-top: 66px;
        width: 945px;

        @include respond(bigTab) {
            width: 100%;
            margin-top: 58px;
        }

        @include respond(phone) {
            margin-top: 35px;
        }
       
        @include respond(desktop) {
            width: 1100px;
        }

        &--top {
            display: flex;
            justify-content: space-between;

            @include respond(bigTab) {
               flex-direction: column;
               justify-content: center;
               align-items: center;
            }
        }

        &--bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 35px;

            @include respond(bigTab) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: unset;
             }
        }

    }

    &__list {
        text-align: left;

        @include respond(bigTab) {
            margin-top: 35px;
        }

        @include respond(phone) {
            margin-left: 30px;
        }


        &--2 {
            margin-right: 120px;

            @include respond(bigTab) {
                margin-right: 0;
            }
        }

        &--4 {
            margin-left: 90px;

            @include respond(phone) {
                margin-left: 30px;
            }
        }

        h3.title {
            font-size: 20px;
            line-height: 26px;
            color: #000 !important;
            font-family: inter;
            font-weight: 600;
            margin-bottom: 35px;
        }

        ul {
            padding: 0;
        }

        li {
            list-style: none;
            display: flex;
            align-items: center;

            @include respond(phone) {
                width: 280px;
            }

            img {
                margin-right: 13px;
                margin-left: -29px;

            }

            span {
                font-size: 20px;
                line-height: 26px;
                color: #969caa;
                font-family: inter;
                font-weight: 300;
            }
        }
    }
}