@import './base';

.eightyPercentContainer {
    width: 80%;
    margin: 0 auto;

    @include respond(phone) {
        width: 90%;
    }
}

@import './components/header';
@import './components/hero';
@import './components/discover';
@import './components/design';
@import './components/fourth';
@import './components/access';
@import './components/feature';
@import './components/beta';
@import './components/footer';
@import './components/plans';


.authHeader, .oldHeader {
    .navbar {
        width: 100%;
        background-color: #ffffff;
        position: fixed;
        top: 0;
        margin:0rem;
        padding:1rem;
        display: block;
        -webkit-box-shadow: 0 8px 6px -6px #999;
        -moz-box-shadow: 0 8px 6px -6px #999;
        box-shadow: 0 8px 6px -6px #999;
        z-index: 10;
    }
    .navbar-auth {
        padding:0rem;
    }
    .nav_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
    }
    .navbar .logo{
        height: 100%;
        // vertical-align: bottom;
    }
    
    .newSeq {
        background: none;
        border: 1px solid #E9E9F3;
        border-radius: 8px;
        font-size: 14px;
        font-family: "Inter";
        padding: 5px 10px;
        color: #000;
        font-weight: 500;
        margin-left: 1rem;
    }
    .navbar .nav_cell{
        display: flex;
        align-items: center;
        margin:0rem;
        padding:0rem;
    }
    .navbar .login_part{
        display: flex;
        justify-content: flex-end;
    }
    
    .navbar-brand {
        font-family: Lato;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */
        color: #000000;
        width: 99px;
        height: 24px;
        margin-left: 0.5rem;
    }
    .nav_center {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        letter-spacing: 0.4375px;
        text-decoration: none;
    
        color: #000000 !important;
        height: 17px;
    }
    .sign {
        width: 86px;
        height: 35px;
    }
    #signin {
        /* position:absolute; */
        background-color:#ffffff;
        border: none;
        height: 17px;
        left: 73.75%;
        right: 18.36%;
        top: calc(50% - 17px/2);
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        letter-spacing: 0.4375px;
        color: #000000;
    }
    #logout {
        left: 86.15%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        background: #7100FF;
        box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
        border-radius: 20px;
        border:none;
        height: 35px;
        top: 33%;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        letter-spacing: 0.4375px;
        color: #FFFFFF;
    }
    #signup{
        /* position: absolute; */
        left: 86.15%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        background: #7100FF;
        box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
        border-radius: 20px;
        border:none;
        height: 35px;
        top: 33%;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        letter-spacing: 0.4375px;
        color: #FFFFFF;
    }
    
    #signup:hover {
      background: #5600FF;
    }
    
    button.btn.btn-primary:hover {
        background: #5600FF;
    }
    
    button.btn.btn-primary:focus {
        background: #5600FF !important;
    }
    
    a.btn.btn-primary:hover {
        background: #5600FF !important;
    }
    
    .user_info {
        margin-right:1rem;
    }
    
    @media only screen and (max-width: 800px) {
        .navbar .login_part{
            display: flex;
            justify-content: flex-end;
            padding-right: 3rem;
        }
      }
    
      .newSeq:hover {
          background: #F1EFFF;
      }
    
      .toolbarBtn:hover {
          background: rgba(138, 155, 168, 0.15);
      }
    
      .bp3-button.bp3-minimal.bp3-intent-primary:hover {
        background: rgba(138, 155, 168, 0.15);
        color: #7100FF;
      }
    
      .bp3-dark.bp3-button.bp3-minimal.bp3-intent-primary:hover {
        color: #5600FF !important;
      }
    
    
      .dropdown .newBtn {
        width: 120px;
      }
    }

    
@import './pages/slug';