.homePlans {
    margin-bottom: 98px;
    width: 100%;
    background: linear-gradient(180deg, #360589 0%, #070714 80%);
    padding-top: 100px;
    padding-bottom: 82px;

    @include respond(smLaptop) {
        padding-top: 70px;
        padding-bottom: 120px;
    }

    .plans {
        display: flex;
        justify-content: space-between;
        @include respond(smLaptop) {
            flex-direction: column;
        }
        @include respond(tablet) {
            align-items: center;
        }
        .mainTitlebox {
            width: 350px;
            margin-top: 84px;

            @include respond(tablet) {
                text-align: center;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            
            h2 {
                color:#fff;
                font-size: 38px;
                font-weight: 700;
            }

            span {
                color: #fff;
                font-size: 20px;
                line-height: 26px;
                font-weight: 100;
               
            }

            .viewPlans {
                background: linear-gradient(320.68deg, #D656FC -13.3%, #7100FF 82.21%);
                box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
                border-radius: 25px;
                padding: 15px 30px;
                margin-top: 45px;
                @include gradientbtn;  
                background-image: linear-gradient(320deg,#7100FF, #D656FC, #D656FC 55%, #7300ffe5);  



                @include respond(tablet) {
                    margin-top: 30px;
                    margin-bottom: 20px;
                }

                span {
                    font-size: 17px;
                    letter-spacing: 0.53px;
                    font-weight: 400;
                }
            }
        }

        .planBoxes {
            display: flex;

            @include respond(smLaptop) {
                margin-top: 60px;
                justify-content: end;
            }

            @include respond(tablet) {
              flex-direction: column;
              align-items: center;
            }

            .planBox {
                width: 360px;

                @include respond(phone) {
                    width: 300px;
                }
                

                &__1 {
                    background: linear-gradient(341.13deg, rgba(15, 6, 44, 0.0001) 9.9%, #7100FF 89.93%);
                    box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.157944);
                    border-radius: 18px;
                    height: 601px;
                    margin-right: 30px;
                    margin-top: 40px;
                    padding-top: 52px;
                    padding-left: 40px;
                    color: #fff;

                    @include respond(tablet) {
                        margin-bottom: 80px;
                        margin-right: 0;
                    }

                    @include respond(phone) {
                        padding-top: 30px;
                        padding-left: 28px;
                        height: 522px;
                    }
                }

                &__2 {
                    background: #FFFFFF;
                    box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.157944);
                    border-radius: 18px;
                    height: 701px;
                    padding-top: 30px;
                    padding-left: 36px;

                    @include respond(phone) {
                        padding-top: 30px;
                        padding-left: 28px;
                        height: 680px;
                    }

                    .nav-item {
                        width: 142px;
                        text-align: center;

                        @include respond(phone) {
                            width: 120px;
                        }
                        
                        .nav-link {
                            color: #969caa;
                            font-size: 14px;
                            letter-spacing: 0.44px;
                        }
    
                        .active {
                            color: #000;
                            font-size: 14px;
                            letter-spacing: 0.44px;
                            background: #FFFFFF;
                            box-shadow: 0px 4px 10px rgba(183, 183, 183, 0.172257);
                            border-radius: 20px;
                        }
                    }
                }

                .free {
                    font-size: 22px;
                    letter-spacing: 0.69px;
                }

                .price {
                    text-align: start;
                    display: flex;
                    margin-top: 15px;
                    .big {
                        font-size: 52px;
                        letter-spacing: 1.63px;
                        margin-right: 8px;
                        line-height: 50px;
                    }
                }

                .indi {
                    font-size: 18px;
                    letter-spacing: 0.56px;
                    margin-top: 20px;
                }

                .perks {
                    margin-top: 30px;
                    .perk {
                        display: flex;
                        margin-bottom: 8px;

                        &__text {
                            font-size: 18px;
                            letter-spacing: 0.56px;
                            margin-left: 13px;
                        }
                    }
                }

                .getStarted {
                    padding: 14px 33px;
                    box-shadow: 0px 13px 15px rgba(51, 105, 254, 0.172257);
                    border-radius: 30.8571px;   
                    margin-top: 115px;
                    margin-left: 55px;



                    @include respond(phone) {
                        margin-top: 70px;
                        margin-left: 35px;
                    }
                    span {
                        font-size: 17px;
                        letter-spacing: 0.53px;
                        color: #000;
                    }
                }

                .tryPro {
                    padding: 13px 51px;
                    background: linear-gradient(320.68deg, #D656FC -13.3%, #7100FF 82.21%);
                    box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
                    border-radius: 30.8571px;
                    margin-top: 30px;
                    margin-left: 55px;
                    @include gradientbtn;  
                    background-image: linear-gradient(320deg,#7100FF, #BC40FD , #bb40fddf 65%, #7300ffe5);  

                    @include respond(phone) {
                        margin-top: 70px;
                        margin-left: 35px;
                    }
                    span {
                        font-size: 19px;
                        letter-spacing: 0.59px;
                        color: #fff;
                    }
                }
            }
        }
    }
}



