.fourthSection {
    margin-top: 194px;
    width: 100%;
    background: linear-gradient(180.01deg, #BE42FD 0.01%, #360589 46.06%, #070714 82.66%);
    .contentBox {
        padding-top: 111px;

        @include respond(phone) {
            padding-top: 70px;
        }
        .quick {
            margin-bottom: 200px;
            display: flex;

            @include respond(bigTab) {
                flex-direction: column-reverse;
                align-items: center;
                margin-bottom: 0;
            }

            .left {
                position: relative;
                width: 780px;
                height: 475px;

                @include respond(bigTab) {
                    width: 100%;
                    margin-top: 30px;
                }

                @include respond(phone) {
                    height: 220px;
                }
                .imgBox {
                    position: absolute;
                    width: 580px;
                    height: 373px;
                    top: 102px;
                    left: 10vw;

                    img {
                        width: 100%;
                    }

                    // @include respond(smLaptop) {
                    //     left: 75px;
                    // }

                    @include respond(bigTab) {
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    @include respond(phone) {
                        height: 232px;
                        width: 360px;
                        top: 48px;

                        @media only screen and (max-width: 400px) {
                            width: 300px;
                        }
                    }

                    // @include respond(desktop) {
                    //     left: 10vw;
                    // }

                    // @include respond(bigScreen) {
                    //     left: 10vw;
                    // }
                }

                .rect1 {
                    position: absolute;
                    left: 185px;
                    top: 0px;

                    @media only screen and (max-width: 700px) {
                        left: 100px;
                    }

                    @media only screen and (max-width: 400px) {
                       left: 60px;
                    }

                    .rectImgBox {
                        height: 51px;
                        width: 497px;

                        @include respond(phone) {
                            height: 25.5px;
                            width: 248.67px;
                        }

                        img{
                            width: 100%;
                        }
                    }
                }

                .rect2 {
                    position: absolute;
                    left: -15px;
                    top: 66px;

                    @include respond(phone) {
                        left: -18.74;
                        top: 33px;
                     }

                    .rectImgBox {
                        width: 567px;
                        height: 51px;

                        @include respond(phone) {
                            height: 25.5px;
                            width: 283.69px;
                        }

                        img{
                            width: 100%;
                        }
                    }
                }

                .rect3 {
                    position: absolute;
                    left: 35px;
                    top: 132px;

                    @media only screen and (max-width: 700px) {
                        left: 30px;
                    }

                    @include respond(phone) {
                        left: 6.28px;
                        top: 66px;
                    }

                    .rectImgBox {
                        width: 567px;
                        height: 51px;

                        @include respond(phone) {
                            height: 25.5px;
                            width: 283.69px;
                        }

                        img{
                            width: 100%;
                        }
                    }
                }

                .rect4 {
                    position: absolute;
                    left: -49.5px;
                    top: 353px;

                    @include respond(smLaptop) {
                        left: -90px;
                    }

                    @include respond(phone) {
                        left: -36px;
                        top: 176.5px;
                    }

                    .rectImgBox {
                        width: 147.5px;
                        height: 51px;

                        @include respond(phone) {
                            height: 25.5px;
                            width: 73.8px;
                        }

                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .right {
                margin-top: 109px;
                width: 280px;
                color: #fff;
                margin-left: 50px;

                @include respond(smLaptop) {
                    margin-left: -30px;
                }

                @include respond(bigTab) {
                    margin-left: 0px;
                    width: unset;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    margin-bottom: 45px;
                    margin-top: 0;
                }

                @include respond(phone) {
                  width: 95%;
                }

                @include respond(desktop) {
                    margin-left: 150px;
                }

                @include respond(bigScreen) {
                    margin-left: 250px;
                }

                h2 {
                    font-family: inter;
                    font-size: 38px;
                    font-weight: black;
                    color: #fff;
                    margin-top: 7px;
                    letter-spacing: 0.8px;
                    margin-bottom: 16px;

                    @include respond(phone) {
                       font-size: 27px;
                       line-height: 32px;
                    }
                }

                span {
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 100;


                    @include respond(phone) {
                        font-size: 17px;
                     }
                }

                .aBox {
                    margin-top: 12px;
                    @include respond(phone) {
                        margin-top: 10px;
                     }
                    a {
                        color: #fff;
                        text-decoration: none;
                        font-size: 16px;
                        text-decoration-line: underline;
                    }
                }

                #signUp {
                    padding: 12px 28px;
                    border-radius: 25px;
                    margin-top: 25px;
                    @include respond(phone) {
                        margin-top: 22px
                     }
                    span {
                        color: #7100ff;
                        font-size: 17px;
                        letter-spacing: 0.53px;
                        font-weight: 400;
                    }
                }
            }
        }

        .codon {
            margin-bottom: 140px;
            display: flex;
            // justify-content: space-between;
            align-items: center;

            @include respond(bigTab) {
                flex-direction: column;
                margin-top: 150px;
                width: 80%;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 120px;
            }

            @include respond(phone) {
                width: 90%;
                margin-bottom: 70px;
            }

            &__left {
                width: 387px;
                color: #fff;
                margin-left: 10%;

                @include respond(bigTab) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 50px;
                    text-align: center;
                    margin-left: 0;
                 }

                h2 {
                    font-family: inter;
                    font-size: 38px;
                    font-weight: black;
                    color: #fff;
                    margin-top: 7px;
                    letter-spacing: 0.8px;
                    margin-bottom: 16px;

                    @include respond(phone) {
                       font-size: 27px;
                       line-height: 32px;
                    }
                }

                span {
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 100;


                    @include respond(phone) {
                        font-size: 17px;
                     }
                }

                .aBox {
                    margin-top: 12px;
                    @include respond(phone) {
                        margin-top: 10px;
                     }
                    a {
                        color: #fff;
                        text-decoration: none;
                        font-size: 16px;
                        text-decoration-line: underline;
                    }
                }

                #tryIt {
                    padding: 12px 28px;
                    background: linear-gradient(320.68deg, #D656FC -13.3%, #7100FF 82.21%);
                    box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
                    border-radius: 25px;
                    margin-top: 25px;
                    @include gradientbtn;
                    background-image: linear-gradient(320deg,#7100FF, #D656FC , #bb40fddf, #7300ffe5);

                    @include respond(phone) {
                        margin-top: 22px
                     }
                    span {
                        color: #fff;
                        font-size: 17px;
                        letter-spacing: 0.53px;
                        font-weight: 400;
                    }
                }
            }

            &__right {
               position: absolute;
               left: 825px;

               @include respond(smLaptop) {
                left: 750px;
            }

                @include respond(bigTab) {
                    position: unset;
                    width: 100%;
                    left: unset;
                    margin-top: 10px;
                }

                @include respond(phone) {
                    margin-top: 0px;
                }


                @include respond(desktop) {
                    left: 928px;
                }

                @include respond(bigScreen) {
                    left: 1030px;
                }
                .imgBox {
                    width: 437px;
                    height: 182px;

                    background: #FFFFFF;
                    box-shadow: 0px 2px 3px rgba(53, 30, 88, 0.03), 0px 10px 19px rgba(117, 98, 137, 0.11);
                    border-radius: 18px;
                    overflow: hidden;

                    @media only screen and (max-width: 1330px) {
                        width: 340px;
                    }

                    @include respond(bigTab) {
                        width: 100%;
                        height: auto;
                    }

                    @include respond(bigScreen) {
                        width: 570px;
                        height: auto;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .borderLine {
            height: 1px;
            background-color: rgba(#979797, 0.2098)
        }

        .easyOnEyes {
            margin-top: 110px;

            @include respond(phone) {
                margin-top: 82px;
            }

            &__textContent {
                width: 321px;
                text-align: center;
                color: #fff;
                margin: 0 auto;

                @include respond(phone) {
                    width: 100%;
                }

                h2 {
                    font-size: 40px;
                    color: #fff;
                    font-weight: black;
                    font-family: inter;
                    margin-bottom: 10px;

                    @include respond(phone) {
                        font-size: 27px;
                    }
                }

                span {
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 100;


                    @include respond(phone) {
                        font-size: 17px;
                     }
                }

            }

            .imgBox {
                width: 100%;
                margin-top: 48px;
                padding-bottom: 167px;
                @include respond(phone) {
                    padding-bottom: 70px;
                }
                img {
                    width: 100%;
                }
            }
        }
    }
}
