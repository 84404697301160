.footer {
    background: rgba(#EAECF4, 0.55);
    mix-blend-mode: normal;
    padding: 20px 0;
    font-family: inter;
    // opacity: 0.55;

    height: auto;
    &__isSlug {
        margin-left: 260px;

    }

    &__container {
        width: 80%;
        margin: 0 auto;

        display: flex;
        justify-content: space-between;

        @include respond(bigTab) {
           flex-direction: column;
           align-items: center;
           width: 100%;
        }

        &--isSlug {
            width: 94.5%;
        }
    }

    &__cell {
        text-align: center;
        span {
            color: #101010;
            font-size: 14px;
            letter-spacing: 0.44px;

            a {
                color: #101010;
                font-size: 14px;
                letter-spacing: 0.44px;
            }
        }

        &--2 {
            @include respond(bigTab) {
                margin-top: 20px;
             }

            .userGuide {
                margin-right: 25px;

                @include respond(bigTab) {
                    margin-right: 15px;
                }
            }

            .privacy {
                margin: 0 25px;

                @include respond(bigTab) {
                    margin: 0 15px;
                }
            }
        }
    }

    a {
        text-decoration: none !important;

        &:hover {
            text-decoration: underline !important;
        }
    }
}
