.design {
    margin-top: 120px;
    font-family: inter;

    &__visualize {
        margin-top: 79px;
    }

    @include respond(bigTab) {
        margin-top: 70px;
    }

    &__contentBox {
        display: flex;
        justify-content: space-between;

        @include respond(bigTab) {
           flex-direction: column;
           align-items: center;
        }

        .left {
            padding-top: 59px;
            width: 469px;

            @include respond(smLaptop) {
                width: 380px;
               
            }

            @include respond(bigTab) {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 400px;
                text-align: center;
                margin-bottom: 50px;
            }

            @include respond(phone) {
                width: 290px;
            }

            &__visualize {
                @include respond(bigTab) {
                  background-color: #f3f5fb;
                  padding-bottom: 50px;
                  border-radius: 22px;
                  width: 100%;
                  margin-bottom: 60px;
                }
            }

            @include respond(bigScreen) {
                width: 580px;
            }

            h2 {
                color: #000;
                font-size: 36px;
                font-weight: 600;
                font-family: inter;
                margin-bottom: 15px;

                @include respond(smLaptop) {
                    font-size: 28px;
                    line-height: 35px;
                }    

                @include respond(phone) {
                    font-size: 22px;
                    margin-bottom: 10px;
                }

                @include respond(bigScreen) {
                    font-size: 40px;
                }
            }

            span {
                font-size: 20px;
                font-weight: normal;
                color: #989caa;
                line-height: 26px;

                @include respond(smLaptop) {
                    font-size: 18px;
                }

                @include respond(phone) {
                    font-size: 17px;
                }

                @include respond(bigScreen) {
                    font-size: 23px;
                    line-height: 35px;
                }
            }

            .aBox {
                margin-top: 15px;
                a {
                    text-decoration: none;
                    font-size: 16px;
                    color: #7100ff;
                    font-weight: 600;
                    text-decoration-line: underline;

                    @include respond(smLaptop) {
                        font-size: 15px;
                    }

                    @include respond(bigScreen) {
                        font-size: 18px;
                    }
                }
            }

            .tryWizardBtn {
                background: linear-gradient(320.68deg, #D656FC -13.3%, #7100FF 82.21%);
                box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
                border-radius: 25px;
                padding: 13px 40px;
                margin-top: 29px;
                @include gradientbtn;
                background-image: linear-gradient(320deg,#7100FF, #D656FC , #bb40fddf, #7300ffe5);  


                &__visualize {
                    margin-top: 17px;
                }

                @include respond(smLaptop) {
                    padding: 10px 30px;
                    margin-top: 20px;
                }

                @include respond(bigScreen) {
                    padding: 15px 45px;
                }

                span {
                    font-size: 16px;
                    letter-spacing: 0.53px;
                    font-weight: 400;
                    color: #fff;

                    @include respond(smLaptop) {
                        font-size: 14px;
                    }

                    @include respond(bigScreen) {
                        font-size: 18px;
                    }
                }
            }
        }

        .right {
            &__visualize {
                @include respond(bigTab) {
                   display: none;
                }
            }
            .outerBox {
                height: 432px;
                width: 528.48px;
                background: linear-gradient(320.68deg, #D656FC -13.3%, #7100FF 82.21%);
                box-shadow: 0px 7px 9px rgba(51, 105, 254, 0.172257);
                border-radius: 16px;
                // padding-top: 17px;
                position: relative;

                @include respond(smLaptop) {
                    width: 430px;
                    height: 352px;
                }

                @include respond(bigTab) {
                    width: 80vw;
                    height: 65vw;
                }

                @include respond(bigScreen) {
                    width: 680px;
                    height: 557px;
                }

                .innerBox {
                    height: 100%;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 3px rgba(53, 30, 88, 0.03), 0px 10px 19px rgba(117, 98, 137, 0.11);
                    border-radius: 18px;
                    position: absolute;
                    top: 17px;
                    right: 17px;
                    padding: 25px;
                    box-sizing: border-box;
                    &__visualize {
                        width: 90%;

                        @include respond(bigTab) {
                            width: 85%;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}


