.newHomePage {
    margin-top: convertToRem(107);

    @include respond(bigTab) {
        margin-top: 55px;
    }
    @include respond(phone) {
        margin-top: 25px;
    }

    font-family: inter;
    .hero {
        .content {
            display: flex;
            justify-content: space-between;
            &__left {
                width: 25vw;

                @include respond(bigTab) {
                    width: 100%;
                }

                .imgBox {
                    height: auto;
                    width: 100%;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    display: none;

                    @include respond(bigTab) {
                       display: block;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            &__info {
                h1 {
                    color: #000;
                    font-size: 44px;
                    font-weight: bold;
                    // margin: 0 0 0.5rem 0;

                    @include respond(smLaptop) {
                        font-size: 35px;
                    }
                }

                span {
                    color: #9B9CAA;
                    font-size: 21px;
                    letter-spacing: 0.29px;

                    @include respond(smLaptop) {
                        font-size: 17px;
                    }
                }
            }

            &__form {
                margin-top: 5px;
                .formGroup {
                    margin-top: 35px;
                    &__group {
                        display: flex;
                        align-items: center;
                    }

                    &__errorText {
                        color: red;
                        font-size: 13px;
                    }
                    @include respond(smLaptop) {
                        margin-top: 25px;
                    }
                    input {
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid $lightGrey;
                        font-size: 17px;
                        letter-spacing: 0.53px;
                        font-weight: medium;
                        padding-left: 40px;
                        margin-left: -24px;
                        padding-bottom: 5px;
                        color: #333;

                        &::placeholder {
                            color: #9B9CAA;
                        }
                    }

                    input.inputError {
                        border-bottom: 1px solid red;

                        &::placeholder {
                            color: red;
                        }
                    }

                    svg {
                        z-index: 10;
                        margin-bottom: 6px;
                    }
                }

                .getStarted {
                  box-shadow: 0px 8px 10px rgba(51, 105, 254, 0.172257);
                    padding: 0.625rem 1.25rem;
                    border-radius: 1.25rem;
                    width: 100%;
                    margin-top: 30px;
                    margin-bottom: 20px;
                    @include gradientbtn;
                    background-image: linear-gradient(320deg,#7100FF, #BC40FD , #bb40fddf 55%, #7300ffe5);   
                    
                    span {
                        color: #fff;
                        font-size: 17px;
                        letter-spacing: 0.53px;

                        @include respond(smLaptop) {
                            font-size: 15px;
                        }
                    }
                }

                span.terms {
                    font-size: 14px;
                    color: #989caa;

                    @include respond(smLaptop) {
                        font-size: 13px;
                    }

                    a {
                        color: #989caa;
                        text-decoration: underline;
                    }
                }
            }

            &__right {
                @include respond(bigTab) {
                    display: none;
                }
                .imgBox {
                    height: auto;
                    width: 54.16vw;
                    margin-right: -56px;
                    margin-top: 10px;

                    @include respond(bigScreen) {
                        width: 45vw;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

