.homeBeta {
    margin-bottom: 120px;
    .bgBox {
        background: linear-gradient(183.14deg, #BE42FD 2.71%, #7302FF 107.21%);
        border-radius: 22px;
        height: 346px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond(bigTab) {
            height: 370px;
        }

        .betaContent {
            width: 486px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include respond(desktop) {
               width: 800px;
            }

            @include respond(phone) {
                width: 270px;
             }
            &__text {
                color: #fff;
                font-size: 34px;
                text-align: center;
                margin-top: 25px;
                line-height: 40px;

                @include respond(bigTab) {
                    font-size: 27px;
                }

                @include respond(phone) {
                    line-height: 37px;
                }
            }

            .joinBeta {
                display: flex;
                align-items: center;
                margin-right: -100px;
                margin-top: 20px;
                cursor: pointer;

                @include respond(phone) {
                    margin-right: 0;
                    margin-left: 50px;
                }

                &__text {
                    margin-left: -50%;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 21px;
                    /* identical to box height */
                    letter-spacing: 0.53125px;
                    color: #FFFFFF;

                    @include respond(phone) {
                        margin-left: -66%;
                    }
                }
            }
        }
    }
}
